<template>
    <div class="flex flex-col h-full" v-if="authStore.auth0 && directusStore.user">
        <Topbar />
        <div class="flex flex-grow">
            <Sidebar />
            <main class="flex-grow p-4 md:p-6 print:px-0">
                <router-view />
            </main>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount } from 'vue';
import { useAuthStore } from './stores/auth'
import { useDirectusStore } from './stores/directus'
import Topbar from "./components/Topbar"
import Sidebar from "./components/Sidebar"

const authStore = useAuthStore()
const directusStore = useDirectusStore()

onBeforeMount(async () => {
    await authStore.setupAuth()
    await directusStore.setupDirectus()
    await directusStore.setUser()
})
</script>