<template>
  <div v-if="store.user" class="grid gap-8">
    <div class="container min-w-0">
      <header class="flex gap-6 mb-4 flex-col md:flex-row">
        <router-link to="/" class="disable-link flex gap-2">
          <span class="text-xl leading-6 font-semibold">{{ $t('menu.participants') }}</span>
        </router-link>

        <BccInput class="md:ml-auto" v-model="store.search_name" :placeholder="t('dashboard.filter.search')" />
      </header>

      <div class="flex flex-wrap gap-2 mb-4">
        <v-select v-if="store.locations.length" class="w-60 mr-2" v-model="store.search_location"
          :placeholder="t('dashboard.potential_placement.title')" :reduce="option => option.value"
          :options="store.locations" :clearable="store.locations.length > 1" />

        <v-select v-if="store.yearGroups.length" class="w-72" v-model="store.search_yearGroup"
          :placeholder="t('dashboard.filter.yearGroup')" :reduce="option => option.value" :options="store.yearGroups" />
      </div>

      <BccTable :columns="columns" :items="sortedParticipants" v-model:sortBy="store.sortBy"
        v-model:sortDirection="store.sortDirection" class="max-w-full overflow-x-auto">

        <template #item.display.balance="{ item }">
          <div class="flex gap-1 items-center">
            <div class="bullet-status bg-silver-tree-500" :title="t('participants.hasSubscription')" v-if="item.display.hasSubscription"></div>
            <div class="bullet-status bg-neptune-100" :title="t('participants.noSubscription')"  v-else></div>
            {{ amountFormatter(item.display.balance, 'de-DE') }}
          </div>
        </template>

        <template #item.actions="{ item }">
          <BccButton variant="tertiary" size="sm" :padding="false" :icon="ChevronRightIcon" iconRight
            @click="seeProfile(item)">{{ t('dashboard.actions.seeProfile') }}</BccButton>
        </template>
        
      </BccTable>

      <div v-if="store.isLoading" class="w-full text-center my-4">
        <BccButton>{{ t('loading') }}</BccButton>
      </div>

      <div v-if="!store.isLoading && !sortedParticipants.length" class="m-4">
        <p>{{ t("dashboard.noParticipantInLocation") }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>

import { onBeforeMount, reactive, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDirectusStore } from '../stores/directus'
import { amountFormatter } from '../helpers/fields.js'
import { useRouter } from 'vue-router'
import { BccInput, BccTable, BccButton } from '@bcc-code/design-library-vue'
import { ChevronRightIcon } from '@bcc-code/icons-vue'

import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"

const router = useRouter()
const directusStore = useDirectusStore()
const directus = directusStore.directus

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
})

const store = reactive({
  isLoading: true,
  user: null,
  search_name: '',
  locations: [],
  search_location: null,
  yearGroups: [],
  search_yearGroup: localStorage.getItem('yearGroup'),
  participants: [],
  sortBy: 'display.name',
  sortDirection: 'ascending'
})

let columns = [
  {
    text: t('dashboard.name'),
    key: 'display.name'
  },
  {
    text: t('dashboard.arrival_date'),
    key: 'display.arrival_date'
  },
  {
    text: t('dashboard.departure_date'),
    key: 'display.departure_date'
  },
  {
    text: t('dashboard.current_employer'),
    key: 'display.current_employer'
  },
  {
    text: t('form.year_group.title'),
    key: "display.year_group"
  },
  {
    text: t('dashboard.actions.title'),
    key: 'actions',
    sortable: false
  }
]

onBeforeMount(async () => {
  if (directusStore.userHasRole('applicant'))
    router.push({ path: '/' })

  await getParticipants()
})

async function getParticipants() {
  store.user = await directus.users.me.read({
    fields: [
      'id', 'first_name', 'last_name',
      'employments.location.id', 'employments.location.name'
    ]
  })

  if (directusStore.isAdmin()) {
    const locations = await directus.items("locations").readByQuery({ limit: -1 })
    store.locations = locations.data.map(location => {
      return {
        value: location.id,
        label: location.name
      }
    })
  }
  else {
    store.locations = store.user.employments.map(employment => {
      return {
        value: employment.location.id,
        label: employment.location.name
      }
    })
  }

  if (store.locations.length) {
    const cachedLocation = localStorage.getItem('location')

    store.search_location = cachedLocation
      ? parseInt(cachedLocation)
      : store.locations.length == 1 ? store.locations[0].value : null

    const data = (await directus.transport.get('/stripe-checkout/status')).data
    store.participants = transformApplicationsData(data)
  }

  // Get year groups from application
  const yearGroups = store.participants
    .filter(e => e.year_group)
    .map(e => {
      return e.year_group
    })
    .flat()
    .map(year_group => {
      return { 'value': year_group, 'label': year_group }
    })
    .sort((a, b) => {
      if (a.label < b.label) return -1
      if (a.label > b.label) return 1
      return 0
    })

  // Set unique values
  store.yearGroups = [...new Map(yearGroups.map(c => [c.label, c])).values()]

  // Filter by the only value in the array
  if (store.yearGroups.length == 1)
    store.search_yearGroup = store.yearGroups[0].value

  store.isLoading = false
}

const sortedParticipants = computed(() => {
  return store.participants
    .filter(participant => {
      return store.search_location ? participant.locations?.includes(store.search_location) : true
    })
    .filter(e => {
      return store.search_yearGroup
        ? e.year_group == store.search_yearGroup : true
    })
    .filter(participant => {
      return (participant.first_name + ' ' + participant.last_name).toLowerCase().includes(store.search_name.toLowerCase())
        || (participant.last_name + ' ' + participant.first_name).toLowerCase().includes(store.search_name.toLowerCase())
    })
})

watch(
  () => store.search_location,
  (tag) => {
    tag != null
      ? localStorage.setItem('location', tag)
      : localStorage.removeItem('location')
  }
)

watch(
  () => store.search_yearGroup,
  (tag) => {
    tag != null
      ? localStorage.setItem('yearGroup', tag)
      : localStorage.removeItem('yearGroup')
  }
)

function transformApplicationsData(data) {
  if (!data) return []
  
  return data.map(item => {
    const display = {};

    display.name = `${item.first_name} ${item.last_name}`;

    if (item.employment) {
      display.arrival_date = item.employment.arrival_date;
      display.departure_date = item.employment.departure_date;
      display.current_employer = item.employment.name;
    }

    if (item.year_group) {
      display.year_group = item.year_group
    }

    display.hasSubscription = !!item.subscription

    return { ...item, display }
  })
}

function seeProfile(participant) {
  router.push({ path: '/dashboard/participants/' + participant.id })
}
</script>