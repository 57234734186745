<template>
  <div v-if="directusStore.user" class="grid gap-8">
    <div class="container min-w-0">
      <header class="flex gap-6 mb-4 flex-col md:flex-row">
        <router-link to="/" class="disable-link flex gap-2">
          <span class="text-xl leading-6 font-semibold">{{ $t('menu.overnighting') }}</span>
        </router-link>

        <BccInput class="md:ml-auto" v-model="store.search_name" :placeholder="t('dashboard.filter.search')" v-if="directusStore.isAdmin() || directusStore.userHasRole('stedsansvarlig')" />
      </header>

      <template v-if="directusStore.isAdmin() || directusStore.userHasRole('stedsansvarlig')">

        <BccTable
          :columns="columns()"
          :items="filteredOvernighting"
          v-model:sortBy="store.sortBy"
          v-model:sortDirection="store.sortDirection"
          class="max-w-full overflow-x-auto"
          v-if="!store.isLoading"
        >

          <template #item.feast_weekend="{ item }">

            <BccBadge v-if="item.feast_weekend == 'not_relevant'" context="neutral">{{
              t("overnighting.status.not_relevant")
            }}</BccBadge>

            <BccBadge v-else-if="item.feast_weekend == 'checking'" context="info">{{
              t("overnighting.status.checking")
            }}</BccBadge>

            <BccBadge v-else-if="item.feast_weekend == 'need_overnighting'" context="danger">{{
              t("overnighting.status.need_overnighting")
            }}</BccBadge>

            <BccBadge v-else-if="item.feast_weekend == 'arranged'" context="success">{{
              t("overnighting.status.arranged")
            }}</BccBadge>

            <BccBadge v-else context="warning">{{
              t("overnighting.status.no_answer")
            }}</BccBadge>

          </template>

          <template #item.brothers_conference="{ item }">
              
            <BccBadge v-if="item.brothers_conference == 'not_relevant'" context="neutral">{{
              t("overnighting.status.not_relevant")
            }}</BccBadge>

            <BccBadge v-else-if="item.brothers_conference == 'checking'" context="info">{{
              t("overnighting.status.checking")
            }}</BccBadge>

            <BccBadge v-else-if="item.brothers_conference == 'need_overnighting'" context="danger">{{
              t("overnighting.status.need_overnighting")
            }}</BccBadge>

            <BccBadge v-else-if="item.brothers_conference == 'arranged'" context="success">{{
              t("overnighting.status.arranged")
            }}</BccBadge>

            <BccBadge v-else context="warning">{{
              t("overnighting.status.no_answer")
            }}</BccBadge>

          </template>

          <template #item.sisters_conference="{ item }">
            
            <BccBadge v-if="item.sisters_conference == 'not_relevant'" context="neutral">{{
              t("overnighting.status.not_relevant")
            }}</BccBadge>

            <BccBadge v-else-if="item.sisters_conference == 'checking'" context="info">{{
              t("overnighting.status.checking")
            }}</BccBadge>

            <BccBadge v-else-if="item.sisters_conference == 'need_overnighting'" context="danger">{{
              t("overnighting.status.need_overnighting")
            }}</BccBadge>

            <BccBadge v-else-if="item.sisters_conference == 'arranged'" context="success">{{
              t("overnighting.status.arranged")
            }}</BccBadge>

            <BccBadge v-else context="warning">{{
              t("overnighting.status.no_answer")
            }}</BccBadge>

          </template>

          <template #item.spring_conference="{ item }">
            
            <BccBadge v-if="item.spring_conference == 'not_relevant'" context="neutral">{{
              t("overnighting.status.not_relevant")
            }}</BccBadge>

            <BccBadge v-else-if="item.spring_conference == 'checking'" context="info">{{
              t("overnighting.status.checking")
            }}</BccBadge>

            <BccBadge v-else-if="item.spring_conference == 'need_overnighting'" context="danger">{{
              t("overnighting.status.need_overnighting")
            }}</BccBadge>

            <BccBadge v-else-if="item.spring_conference == 'arranged'" context="success">{{
              t("overnighting.status.arranged")
            }}</BccBadge>

            <BccBadge v-else context="warning">{{
              t("overnighting.status.no_answer")
            }}</BccBadge>

          </template>

        </BccTable>

        <div v-if="store.isLoading" class="w-full text-center my-4">
          <BccButton>{{ t('loading') }}</BccButton>
        </div>

      </template>

      <template v-else>
        <p class="text-body-base text-secondary whitespace-pre-wrap">{{ t('overnighting.info') }}</p>

        <BccBadge v-if="store.showNoOvernightingsMessage" class="mt-6" context="warning">{{
          t("overnighting.noApplication")
        }}</BccBadge>

        <form @submit.prevent="saveOvernighting" class="xl:max-w-[90%] w-full">
          <FormSection>
            <table class="">
              <thead>
                <tr>
                  <th class="font-semibold text-left w-[200px]">{{ t('overnighting.events.title') }}</th>
                  <th class="font-semibold text-left">{{ t('overnighting.status.title') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(status, event) in store.overnighting.events" :key="event">
                  <tr class="event">
                    <td>{{ t('overnighting.events.' + event) }}</td>
                    <v-select class="my-2" placeholder="---" required v-model="store.overnighting.events[event]"
                      :reduce="option => option.value" :options="store.list.status" />
                  </tr>
                </template>
              </tbody>
            </table>
          </FormSection>

          <BccButton variant="primary" class="mr-auto" :disabled="store.isLoading"
            :class="{ 'cursor-not-allowed': store.isLoading }" type="submit">
            <div class="spinner" :class="{ loading: store.isLoading }"></div>
            {{ t('form.save') }}
          </BccButton>
        </form>
      </template>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, reactive, computed, watch } from 'vue'
import { useI18n } from "vue-i18n";
import { useDirectusStore } from "../stores/directus.js";
import {
  BccInput,
  BccTable,
  BccBadge,
  BccButton,
} from "@bcc-code/design-library-vue";
import FormSection from '../components/FormSection'

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

const directusStore = useDirectusStore()
const user = directusStore.user
const directus = directusStore.directus

const { t, locale } = useI18n({
  inheritLocale: true,
  useScope: "local",
});

let selectOptionsKeysTranslatable = {
  status: ['not_relevant', 'checking', 'need_overnighting', 'arranged']
}
let selectOptionsList = {}

// Translatable values
Object.entries(selectOptionsKeysTranslatable).map(([key, value]) => {
  selectOptionsList[key] = value.map(val => ({ 'value': val, 'label': t('overnighting.' + key + '.' + val) }))
})

const store = reactive({
  isLoading: true,
  overnightings: [],
  showNoOvernightingsMessage: false,
  search_name: '',
  sortBy: "display.name",
  sortDirection: "ascending",

  selectOptionsKeysTranslatable: selectOptionsKeysTranslatable,
  list: selectOptionsList,

  overnighting: {
    id: null,
    application_id: null,
    events: {
      feast_weekend: null,
      brothers_conference: null,
      sisters_conference: null,
      spring_conference: null,
    }
  },
})

watch(
  locale,
  () => updateSelectOptionsLists()
)

const columns = () => [
  {
    text: t("dashboard.name"),
    key: "display.name"
  },
  {
    text: t("overnighting.events.feast_weekend"),
    key: "feast_weekend"
  },
  {
    text: t("overnighting.events.brothers_conference"),
    key: "brothers_conference"
  },
  {
    text: t("overnighting.events.sisters_conference"),
    key: "sisters_conference"
  },
  {
    text: t("overnighting.events.spring_conference"),
    key: "spring_conference"
  },
]

function transformOvernightingsData(data) {
  return data.map((item) => {
    const display = {};

    display.name = item.application_id?.first_name
      ? `${item.application_id?.first_name} ${item.application_id?.last_name}`
      : '-';

    return { ...item, display }
  })
}

const filteredOvernighting = computed(() => {
  return store.overnightings
    .filter(item => item.application_id)
    // Filter only next year group & no possible_start_date
    .filter(item => item.application_id?.year_group == getCurrentYearGroup() || !item.application_id?.year_group)
    .filter(item => {
      return (item.application_id.first_name + ' ' + item.application_id.last_name).toLowerCase().includes(store.search_name.toLowerCase())
      || (item.application_id.last_name + ' ' + item.application_id.first_name).toLowerCase().includes(store.search_name.toLowerCase())
    })
})

onBeforeMount(async () => {
  if (directusStore.isAdmin() || directusStore.userHasRole('stedsansvarlig')) {
    await getParticipantsOvernighting()
    
    if (store.overnightings.length == 0) {
      store.showNoOvernightingsMessage = true
    }
  }
  else if (directusStore.userHasRole('applicant')) {
    await getExistingOvernighting()
  }
  else {
    router.push({ path: "/" });
  }

  store.isLoading = false
})

async function getParticipantsOvernighting() {
  const overnightings = await directus.items("overnighting").readByQuery({
    fields: ['*', 'application_id.id', 'application_id.first_name', 'application_id.last_name', 'application_id.year_group'],
    limit: -1
  })

  if (!overnightings.data) return

  store.overnightings = transformOvernightingsData(overnightings.data)
}

async function getExistingOvernighting() {
  const overnighting = await directus.items("overnighting").readByQuery({
    filter: {
      application_id: { _eq: user.applications[user.applications.length-1] }
    },
    fields: ['*']
  })

  store.overnighting.application_id = { id: user.applications[user.applications.length-1] }

  if (!overnighting.data.length) return

  store.overnighting.id = overnighting.data[0].id
  store.overnighting.events.feast_weekend = overnighting.data[0].feast_weekend
  store.overnighting.events.brothers_conference = overnighting.data[0].brothers_conference
  store.overnighting.events.sisters_conference = overnighting.data[0].sisters_conference
  store.overnighting.events.spring_conference = overnighting.data[0].spring_conference
}

async function saveOvernighting() {
  store.isLoading = true

  let data = {
    application_id: store.overnighting.application_id.id,
    feast_weekend: store.overnighting.events.feast_weekend,
    brothers_conference: store.overnighting.events.brothers_conference,
    sisters_conference: store.overnighting.events.sisters_conference,
    spring_conference: store.overnighting.events.spring_conference
  }

  if (!store.overnighting.id) {
    const response = await directus.items('overnighting').createOne(data)
    store.overnighting.id = response.id
  }
  else {
    await directus.items('overnighting').updateOne(store.overnighting.id, data)
  }

  store.isLoading = false
}

function updateSelectOptionsLists() {
  Object.entries(store.selectOptionsKeysTranslatable).map(([key, value]) => {
    store.list[key] = value.map(val => ({ 'value': val, 'label': t('overnighting.' + key + '.' + val) }))
  })
}

function getCurrentYearGroup() {
  const date = new Date()
  return date.getMonth()+1 >= 8
    ? `${(date.getFullYear()).toString().slice(-2)}/${(date.getFullYear()+1).toString().slice(-2)}`
    : `${(date.getFullYear()-1).toString().slice(-2)}/${(date.getFullYear()).toString().slice(-2)}`
}
</script>