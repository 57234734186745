<template>
    <div class="container h-full relative">
        <header class="mb-4">
            <div v-html="store.home"></div>
        </header>

        <div class="mb-8 flex gap-4">
            <v-select
                class="w-60"
                v-model="store.selectedLocation"
                v-if="store.locations.length"
                :placeholder="t('home.selectLocation')"
                :reduce="(option) => option.value"
                :options="store.locations"
            />
            
            <v-select
                class="w-60 hidden"
                v-model="store.selectedYearGroup"
                :placeholder="t('home.selectYearGroup')"
                :reduce="(option) => option.value"
                :options="store.yearGroups"
            />
        </div>

        <div v-if="!store.loading">
            <ul class="list-decimal list-inside flex flex-col gap-3">
                <li v-for="page in store.selectedPages" :key="page.id">
                    <a class="underline" :href="'/info/' + page.slug">{{ page.title }}</a>
                </li>
            </ul>
            
        </div>
        <div v-else class="flex h-full justify-center items-center">
            <BccSpinner size="xs" />
        </div>
    </div>
</template>
  
<script setup>
import { onBeforeMount, reactive, watch } from 'vue'
import { useDirectusStore } from '../stores/directus'
import { useI18n } from 'vue-i18n'
import { BccSpinner } from '@bcc-code/design-library-vue';

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

const directusStore = useDirectusStore()
const directus = directusStore.directus
const user = directusStore.user

const { t } = useI18n({
    inheritLocale: true,
    useScope: 'local'
})

const store = reactive({
    loading: true,
    home: null,
    pages: [],
    locations: [],
    yearGroups: ['24/25'],
    selectedPages: [],
    selectedLocation: null,
    selectedYearGroup: null,
})

onBeforeMount(async () => {
    await getPages()
    store.loading = false
})

watch(
    () => store.selectedLocation,
    () => changeInfoPages()
)
watch(
    () => store.selectedYearGroup,
    () => changeInfoPages()
)

async function getPages() {
    const pages = await directus.items("pages").readByQuery({
        fields: ['*', 'targeted_locations.locations_id.*']
    })
    if (!pages.data.length) return
    
    store.home = pages.data.find(page => page.slug == 'home')?.content
    store.pages = pages.data.filter(page => page.status == 'published')

    let currentOrComingEmployment = null

    if (user.current_employment || user.employments?.length) {
        currentOrComingEmployment = user.current_employment
            ? user.employments.find(employment => employment.id == user.current_employment.key)
            : user.employments[0] // Get the first employment
    }

    store.pages.forEach(page => {
        if (!page.targeted_locations.length) {
            return
        }

        const pageLocations = page.targeted_locations
            .map(location => ({
                value: location.locations_id?.id,
                label: location.locations_id?.name
            }))

        store.locations = store.locations.concat(pageLocations)

        if ((currentOrComingEmployment
            && page.targeted_locations.length
            && !page.targeted_locations.map(l => l.locations_id.id).includes(currentOrComingEmployment.location.id))
            // || (page.year_groups.length
            // && !page.year_groups.includes(currentOrComingEmployment.yearGroup))
        ) {
            return
        }
        
        store.selectedPages.push(page)
    })

    // Unique locations
    store.locations = [...new Map(store.locations.map(location => [location.value, location])).values()]

    store.selectedLocation = currentOrComingEmployment?.location.id
}

function changeInfoPages() {
    store.selectedPages = []

    store.pages.forEach(page => {
        if (page.targeted_locations.length
            && !page.targeted_locations
                .map(l => l.locations_id?.id)
                .includes(store.selectedLocation)
            )
            // && !(page.year_groups.includes(store.selectedYearGroup))
            return

        store.selectedPages.push(page)
    })
}
</script>