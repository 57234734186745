<template>
  <div v-if="user" class="grid gap-8">
    <div class="container min-w-0">
      <header class="flex gap-6 mb-4 flex-col md:flex-row">
        <router-link to="/" class="disable-link flex gap-2">
          <span class="text-xl leading-6 font-semibold">{{ $t('menu.norwegianAssessments') }}</span>
        </router-link>

        <BccInput class="md:ml-auto" v-model="store.search_name" :placeholder="t('dashboard.filter.search')" />
      </header>

      <div class="flex mb-4 gap-4" v-if="isMultiReferencePerson()">
        <v-select v-if="isMultiReferencePerson() && store.localChurches" class="w-60" v-model="store.search_localChurch"
          :placeholder="t('dashboard.local_church')" :reduce="option => option.value" :options="store.localChurches" />

        <v-select v-if="store.yearGroups" class="w-60" v-model="store.search_yearGroup"
          :placeholder="t('form.year_group.title')" :reduce="option => option.value" :options="store.yearGroups" />
      </div>

      <BccTable :columns="columns()" :items="filteredApplications"
        v-model:sortBy="store.sortBy" v-model:sortDirection="store.sortDirection" class="max-w-full overflow-x-auto">

        <template #item.display.name="{ item }">
          <span :class="item.status == 'application_withdrawn' ? 'line-through' : ''">{{ item.display.name }}</span>
        </template>

        <template #item.display.languageStatus="{ item }">
          <BccBadge :context="item.display.languageStatus.context" class="w-[18px] h-[18px] md:w-auto md:h-auto rounded-full md:rounded-md ml-2.5 mt-1.5 md:ml-0 md:mt-0 hide-text-on-mobile">
            {{item.display.languageStatus.text}}
          </BccBadge>
        </template>

        <template #item.actions="{ item }">
          <BccButton variant="tertiary" size="sm" :padding="false" :icon="ChevronRightIcon" iconRight
            @click="assessLanguageLevel(item)">{{ t('dashboard.actions.evaluation') }}</BccButton>
        </template>

      </BccTable>

      <div v-if="store.isLoading" class="text-center w-full my-4">
        <BccButton>{{ t('loading') }}</BccButton>
      </div>

      <div v-if="!store.isLoading && !filteredApplications.length" class="m-4">
        <p>{{ t('dashboard.noChurchApplication') }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useDirectusStore } from '../stores/directus'
import { useRouter } from 'vue-router'
import { onBeforeMount, computed, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { getCurrentDate } from "../helpers/fields.js";
import { BccInput, BccTable, BccButton, BccBadge, BccPin } from '@bcc-code/design-library-vue'
import { ChevronRightIcon } from '@bcc-code/icons-vue'

import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
})

const store = reactive({
  isLoading: true,
  directus: null,
  user: null,
  localChurches: [],
  yearGroups: [],
  search_localChurch: null,
  search_yearGroup: null,
  search_name: '',
  applications: [],
  sortBy: 'display.name',
  sortDirection: 'ascending'
})

const router = useRouter()
const directusStore = useDirectusStore()
const user = directusStore.user
const directus = directusStore.directus

const columns = () => [
  {
    text: t('dashboard.name'),
    key: "display.name",
  },
  {
    text: t('dashboard.status.title'),
    key: "display.languageStatus",
    sortMethod: (a, b) => {
      let modifier = 1
      if (store.sortDirection === 'descending') modifier = -1

      if (a.display.languageStatus.text < b.display.languageStatus.text) return -1 * modifier
      if (a.display.languageStatus.text > b.display.languageStatus.text) return 1 * modifier
      return 0
    }
  },
  {
    text: t('dashboard.actions.title'),
    key: "actions",
    sortable: false
  }
]

onBeforeMount(async () => {
  if (directusStore.userHasRole('applicant'))
    router.push({ path: '/' })

  await getApplications()
})

const filteredApplications = computed(() => {
  return store.applications
    .filter(a => {
      return store.search_localChurch
        ? a.local_church?.id == store.search_localChurch : true
    })
    .filter(a => {
      return store.search_yearGroup
        ? a.year_group == store.search_yearGroup : true
    })
    .filter(a => {
      return (a.first_name + ' ' + a.last_name).toLowerCase().includes(store.search_name.toLowerCase())
        || (a.last_name + ' ' + a.first_name).toLowerCase().includes(store.search_name.toLowerCase())
    })
})

function transformApplicationsData(data) {
  return data
    .map(item => {
      const display = {}

      if (item.first_name && item.last_name) {
        display.name = `${item.first_name} ${item.last_name}`
      }

      // Status

      display.languageStatus = {}
      display.languageStatus.text = item.language_status ? t('languageAssessment.form.language_status.' + item.language_status) : '-'

      if (item.language_status == 'not_started') {
        display.languageStatus.context = 'neutral'
      }
      else if (item.language_status == 'too_low') {
        display.languageStatus.context = 'warning'
      }
      else if (item.language_status == 'on_track' || item.language_status == 'passed') {
        display.languageStatus.context = 'success'
      }

      return { ...item, display }
    })
}

async function getApplications() {
  const applications = await directus.items("applications").readByQuery({
    filter: {
      // Filter coming participants
      possible_start_date: {
        _gte: "$NOW"
      }
    },
    fields: [
      'id', 'year_group', 'possible_start_date', 'first_name', 'last_name', 'status', 'language_status',
      'local_church.id', 'local_church.name',
    ],
    limit: -1
  })
  store.applications = transformApplicationsData(applications.data)

  // Get local churches from applications
  const churches = store.applications
    .filter(a => a.local_church)
    .map(a => { return { 'value': a.local_church.id, 'label': a.local_church.name } })
    .sort((a, b) => {
      if (a.label < b.label) return -1
      if (a.label > b.label) return 1
      return 0
    })

  // Set unique values
  store.localChurches = [...new Map(churches.map(c => [c.label, c])).values()]
  store.yearGroups = [...new Set(store.applications.map(a => a.year_group))]
    .filter(year => year)
    .sort()
    .map(year => { return { value: year, label: t('form.year_group.title') + ": " + year } })

  store.isLoading = false
}

function assessLanguageLevel(application) {
  router.push({ path: '/application/' + application.id + '/language-status' })
}

function isMultiReferencePerson() {
  return directusStore.isAdmin() || directusStore.userHasRole('norwegian_teacher') || (directusStore.userHasRole('norwegian_teacher') && user.administrator_for.length > 1)
}
</script>